@import url(theme/fonts.scss);
@import "theme/sizes.scss";
@import "theme/colors.scss";

html {
  font-size: 16px;
  @media only screen and (max-width: 440px) {
    font-size: vw-mobile(16);
  }
}

body {
  margin: 0;
  font-family: "Montserrat-Regular", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  height: 12px;
  width: 12px; /* Total width including `border-width` of scrollbar thumb */
}
::-webkit-scrollbar-track {
  background: rgba(238, 238, 238, 0.2);
}
::-webkit-scrollbar-thumb {
  border: 3px solid rgba(0, 0, 0, 0); /* Transparent border together with `background-clip: padding-box` does the trick */
  background-clip: padding-box;
  border-radius: 12px;
  background-color: #a66fac90;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.025);
  transition: background-color 0.3s;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #a66fac;
}
::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}

body::-webkit-scrollbar {
  height: 12px; /* Total width including `border-width` of scrollbar thumb */
  width: 12px; /* Total width including `border-width` of scrollbar thumb */
}
body::-webkit-scrollbar-track {
  background: rgba(238, 238, 238, 0.2);
}
body::-webkit-scrollbar-thumb {
  border: 3px solid rgba(0, 0, 0, 0); /* Transparent border together with `background-clip: padding-box` does the trick */
  background-clip: padding-box;
  border-radius: 12px;
  background-color: #a66fac90;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.025);
  transition: background-color 0.3s;
}
body::-webkit-scrollbar-thumb:hover {
  background-color: #a66fac;
}
body::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
body::-webkit-scrollbar-corner {
  background-color: transparent;
}

@media only screen and (max-width: 440px) {
  ::-webkit-scrollbar {
    height: vw-mobile(10);
    width: vw-mobile(
      10
    ); /* Total width including `border-width` of scrollbar thumb */
  }
  ::-webkit-scrollbar-thumb {
    border: vw-mobile(2) solid rgba(0, 0, 0, 0); /* Transparent border together with `background-clip: padding-box` does the trick */
    border-radius: vw-mobile(10);
    box-shadow: inset 0 0 0 vw-mobile(1) rgba(0, 0, 0, 0.025);
  }

  body::-webkit-scrollbar {
    height: vw-mobile(
      10
    ); /* Total width including `border-width` of scrollbar thumb */
    width: vw-mobile(
      10
    ); /* Total width including `border-width` of scrollbar thumb */
  }
  body::-webkit-scrollbar-thumb {
    border: vw-mobile(2) solid rgba(0, 0, 0, 0); /* Transparent border together with `background-clip: padding-box` does the trick */
    border-radius: vw-mobile(8);
    box-shadow: inset 0 0 0 vw-mobile(1) rgba(0, 0, 0, 0.025);
  }
}
